import { DrupalJsonApiParams } from 'drupal-jsonapi-params'

export const getMenu = () => {
  return apiFetch('/api/menu_items/main')
}

const getDrupalJsonApiParamsString = (options) => {
  const apiParams = new DrupalJsonApiParams()
  if (options.groups) {
    Object.keys(options.groups).forEach((group) => {
      apiParams.addGroup(group, options.groups[group])
    })
  }
  if (options.filters) {
    Object.keys(options.filters).forEach((filter) => {
      let path = filter
      let value = options.filters[filter]
      let operator = undefined
      let group = undefined
      if (typeof value === 'object') {
        path = value.path || filter
        operator = value.operator
        group = value.group
        value = value.data
      }
      apiParams.addFilter(path, value, operator, group)
    })
  }
  if (options.includes) {
    apiParams.addInclude(options.includes)
  }
  if (options.fields) {
    Object.keys(options.fields).forEach((field) => {
      apiParams.addFields(field, options.fields[field])
    })
  }
  if (options.sort) {
    options.sort.forEach((field) => {
      apiParams.addSort(field)
    })
  }
  if (options.limit) {
    apiParams.addPageLimit(options.limit)
  }
  if (options.offset) {
    apiParams.addPageOffset(options.offset)
  }
  if (options.offset) {
    apiParams.addPageOffset(options.offset)
  }
  return apiParams.getQueryString()
}

function pick(obj: Record<string, any>, keys: string[]) {
  if (keys === undefined) return obj?.data
  if (obj === undefined) return {}

  const newObj = {}
  for (const key of keys) {
    ;(newObj as any)[key] = obj[key]
  }
  return newObj
}

export const getDrupalNodeQueryParams = (contentType, params = {}) => {
  const appConfig = useAppConfig()
  const baseOptions = appConfig.drupalParams.nodes[contentType]
  const options = { ...baseOptions, ...params }
  return getDrupalJsonApiParamsString(options)
}

export const getDrupalNodesURLOptions = (contentType, params = {}) => {
  const { filters } = params
  const hasNid = filters?.nid && typeof filters.nid !== 'object'
  const route = useRoute()
  const isPreviewMode = hasNid && (filters.nid.includes('-') || route.query?.mode == 'preview')
  const queryParams = getDrupalNodeQueryParams(contentType, params)

  let urlPath = `/${contentType}`
  let viewMode = 'view'

  if (isPreviewMode) {
    viewMode = 'preview'
    urlPath = filters?.nid
  }

  const baseUrl =
    viewMode === 'view' ? '/jsonapi/node' : '/jsonapi/decoupled-preview/'
  const url = `${baseUrl}${urlPath}?${queryParams}`
  return { url, viewMode }
}

export const getDrupalNodesURL = (contentType, params = {}) => {
  const runtimeConfig = useRuntimeConfig()
  const { url } = getDrupalNodesURLOptions(contentType, params)
  return runtimeConfig.public.API_BASE_URL + url
}

export const getDrupalNodes = (contentType, params?, picks?) => {
  const { url, viewMode } = getDrupalNodesURLOptions(contentType, params)

  const fetchOptions = { pick: ['data'] }
  if (viewMode !== 'view') {
    fetchOptions.cookie = true
  }

  return apiFetch(url, fetchOptions).then((response) => {
    if (viewMode === 'view') {
      return params?.filters?.nid
        ? response?.data?.value?.data?.[0]
        : pick(response?.data?.value, picks)
    }
    return response?.data?.value?.data
  })
}

export const getArticles = (params?, picks?) => {
  return getDrupalNodes('article', params, picks)
}

export const getPages = (params?, picks?) => {
  return getDrupalNodes('page', params, picks)
}

export const getFormations = (params?, picks?) => {
  return getDrupalNodes('formation', params, picks)
}

export const getFormationsURL = (params?) => {
  return getDrupalNodesURL('formation', params)
}

export const getMetiers = (params?, picks?) => {
  return getDrupalNodes('metier', params, picks)
}

export const getEvenements = (params?, picks?) => {
  return getDrupalNodes('evenement', params, picks)
}

export const getMenusRestaurant = (params?, picks?) => {
  return getDrupalNodes('menu_restaurant', params, picks)
}

export const getOffresEmploi = (params?, picks?) => {
  return getDrupalNodes('offres_emploi', params, picks)
}

export const getBannieres = (params?, picks?) => {
  return getDrupalNodes('banniere_cta', params, picks)
}

export const getTaxonomyTermsURL = (vocabulary, params = {}) => {
  const runtimeConfig = useRuntimeConfig()
  const appConfig = useAppConfig()
  const options = {
    sort: ['name'],
    ...appConfig.drupalParams.terms[vocabulary],
    ...params,
  }
  const queryParams = getDrupalJsonApiParamsString(options)
  return (
    runtimeConfig.public.API_BASE_URL +
    `/jsonapi/taxonomy_term/${vocabulary}?${queryParams}`
  )
}

export const getTaxonomyTerms = (vocabulary, params = {}) => {
  const appConfig = useAppConfig()
  const options = {
    sort: ['name'],
    ...appConfig.drupalParams.terms[vocabulary],
    ...params,
  }
  const queryParams = getDrupalJsonApiParamsString(options)
  const url = `/jsonapi/taxonomy_term/${vocabulary}?${queryParams}`
  return apiFetch(url, { pick: ['data'] }).then(
    (resp) => resp?.data?.value?.data
  )
}

export const getDiplomes = (params?) => {
  return getTaxonomyTerms('diplome', params)
}

export const getTypesContrat = (params?) => {
  return getTaxonomyTerms('type_de_contrat', params)
}

export const getFilieres = (params?) => {
  return getTaxonomyTerms('filiere', params)
}

export const getCategories = (params?) => {
  return getTaxonomyTerms('categorie', params)
}

export const getTypeEvenements = (params?) => {
  return getTaxonomyTerms('type_d_evenement', params)
}

export const getPermissions = (params?) => {
  return getTaxonomyTerms('permission_de_contenus', params)
}

export const getWorkflows = (params?) => {
  let url = '/jsonapi/workflow/workflow'
  if (params) {
    url = url + '?' + getDrupalJsonApiParamsString(params)
  }

  return apiFetch(url, { pick: ['data'] })
}

export const getBlockContentURL = (block, params = {}) => {
  const runtimeConfig = useRuntimeConfig()
  const appConfig = useAppConfig()
  const options = {
    sort: ['info'],
    ...appConfig.drupalParams.block_content[block],
    ...params,
  }

  const queryParams = getDrupalJsonApiParamsString(options)
  return (
    runtimeConfig.public.API_BASE_URL +
    `/jsonapi/block_content/${block}?${queryParams}`
  )
}

export const getBlockContent = (block, params = {}) => {
  const appConfig = useAppConfig()
  const options = {
    sort: ['info'],
    ...appConfig.drupalParams.block_content[block],
    ...params,
  }
  const queryParams = getDrupalJsonApiParamsString(options)
  const url = `/jsonapi/block_content/${block}?${queryParams}`
  return apiFetch(url, { pick: ['data'] }).then(
    (resp) => resp?.data?.value?.data
  )
}

export const getInscriptions = (params?, type='evenement') => {
  let url = `/jsonapi/registration/${type}`
  if (params) {
    url = url + '?' + getDrupalJsonApiParamsString(params)
  }

  return apiClientFetch(url, { pick: ['data'] }).then((resp) => resp?.data)
}

export const inscription = (user?, params?, type='evenement') => {
  const endpoint = params?.endpoint || `/jsonapi/registration/${type}`
  const id = params?.id

  let data = {
    id,
    type: params?.type || `registration--${type}`,
    attributes: {
      anon_mail: params?.anonMail,
      field_nom: params?.nom,
      field_prenom: params?.prenom,
      field_telephone: params?.telephone,
      mail: params?.mail,
      state: params?.state || 'pending',
      count: params?.places,
    },
    relationships: {
      author_uid: {
        data: {
          type: 'user--user',
          id: user?.id,
        },
      },
    },
  }

  if (!id) {
    data.relationships['workflow'] = {
      data: {
        type: 'workflow--workflow',
        id: params?.workflowId || '5d8a5016-97b3-400d-82e0-71b04f2d149e',
      },
    }
    data.attributes['entity_type_id'] = params?.entityTypeId || 'node'
    data.attributes['entity_id'] = params?.entityId || getIdFromRoute()

    if (user?.mail) {
      data.relationships['user_uid'] = {
        data: {
          type: 'user--user',
          id: user?.id,
        },
      }
    }
  }

  return apiMutate(
    endpoint,
    { data },
    {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
    }
  )
}

export const uploadFile = (values, path?) => {
  const endpoint = path || '/entity/file?_format=json'

  let headers = {}
  let data = {
    uid: [{ target_id: values?.uid || '0' }],
    filename: [
      {
        value: values.filename,
      },
    ],
    filemime: [
      {
        value: values?.filemime,
      },
    ],
    uri: [
      {
        value: values.uri,
      },
    ],
    data: [
      {
        value: values?.data,
      },
    ],
  }
  if (values.binary && path) {
    data = values.binary
    headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${values.filename}"`,
    }
  }

  return apiMutate(endpoint, data, { headers })
}

export const submitWebform = (values, webform_id) => {
  const endpoint = '/webform_rest/submit?_format=json'

  const data = {
    webform_id,
    ...values,
  }
  if (values?.entity_id) {
    data.entity_type ='node'
  }

  return apiMutate(endpoint, data)
}

export const candidature = (values) => {
  return submitWebform(values, 'candidature_offre_emploi')
}

export const contact = (values) => {
  return submitWebform(values, 'contact')
}

export const ajoutOffreEmploi = (values?) => {
  const endpoint = '/jsonapi/node/offres_emploi'

  const relationships = {
    node_type: {
      data: {
        type: 'node_type--node_type',
        id: '1bda94c9-e1df-4f92-9754-ed60af0f9e01',
      },
    },
  }
  if (values.entreprise) {
    relationships.field_entreprise = {
      data: {
        type: 'user--user',
        id: values.entreprise,
      },
    }
  }
  if (values.diplome && values.diplome.length > 0) {
    relationships.field_diplome = {
      data: values.diplome.map((diplomeId) => ({
        type: 'taxonomy_term--diplome',
        id: diplomeId,
      })),
    }
  }
  if (values.typeContrat && values.typeContrat.length > 0) {
    relationships.field_type_de_contrat = {
      data: values.typeContrat.map((typeContratId) => ({
        type: 'taxonomy_term--type_de_contrat',
        id: typeContratId,
      })),
    }
  }
  if (values.formation && values.formation.length > 0) {
    relationships.field_formation_relation = {
      data: values.formation.map((formationId) => ({
        type: 'node--formation',
        id: formationId,
      })),
    }
  }

  const data = {
    type: 'node--offres_emploi',
    attributes: {
      title: values.intitule,
      body: {
        value: values.description,
        format: 'basic_html',
      },
      field_contact: values.contact,
      field_emploi_date: {
        value: values.dateLimite,
      },
      field_emploi_hebergement: values.hebergement,
      field_emploi_duree_contrat: values.duree,
      field_experience_exigee: values.experience,
      field_lieu_travail: values.lieuTravail,
      moderation_state: {
        value: values.moderationState || 'draft',
      },
    },
    relationships: relationships,
  }

  if (values.id) {
    data.id = values.id
  }
  
  return apiMutate(
    endpoint,
    { data },
    {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
    }
  )
}

export const ajoutEntreprise = (values?) => {
  const endpoint = '/jsonapi/profile/entreprise'

  const data = {
    type: 'profile--entreprise',
    attributes: {
      status: true,
      is_default: false,
      field_adresse: values.adresse,
      field_info_de_l_entreprise: values.info,
      field_nombre_de_salaries: values.nbSalaries,
      field_raison_sociale: values.raisonSociale,
      field_siret: values.siret,
    },
    relationships: {
      profile_type: {
        data: {
          type: 'profile_type--profile_type',
          id: '85ca4039-9701-42c0-8bf3-a73d1c34bee5',
        },
      },
      uid: {
        data: {
          type: 'user--user',
          id: values.user,
        },
      },
    },
  }

  if (values.id) {
    data.id = values.id
  }
  
  return apiMutate(
    endpoint,
    { data },
    {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
    }
  )
}

