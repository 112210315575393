import { useLogout } from '~/composables/auth'

export default defineAppConfig({
  drupalParams: {
      nodes: {
          article: {
              includes: [
                'field_filiere',
                'field_categorie',
                'field_illustration_principale',
                'field_illustration_principale.field_media_image',
                'field_contenus_en_relation',
                'field_contenus_en_relation.field_illustration_principale',
                'field_contenus_en_relation.field_illustration_principale.field_media_image'
              ],
              fields: {
                'file--file': ['uri', 'url', 'image_style_uri'],
                'media--image': ['field_media_image'],
              }
          },
          formation: {
              includes: [
                'field_contact_formation',
                'field_contact_formation.user_picture',
                'field_contact_formation.collaborateur_profiles',
                'field_fichier',
                'field_filiere',
                'field_filiere.field_icon',
                'field_filiere.field_img_bg_bloc_meti',
                'field_diplome',
                'field_image_illustration',
                'field_responsable_de_contenu', 
                'field_type_de_contrat',
                'field_metier_reference',
                'field_metier_reference.field_icone_metier',
              ],
              fields: {
                'file--file': ['uri', 'url', 'image_style_uri'],
                'media--document': ['field_media_document'],
                'node--metier': ['field_icone_metier', 'title', 'drupal_internal__nid'],
                'profile--collaborateur': ['field_poste'],
                'taxonomy_term--diplome': [
                  'name',
                  'description',
                  'field_diplome_acronyme',
                  'drupal_internal__tid',
                  'path',
                  'weight'
                ],
                'taxonomy_term--filiere': [
                  'name',
                  'description',
                  'drupal_internal__tid',
                  'path',
                  'field_image',
                  'field_icon',
                  'field_img_bg_bloc_meti',
                ],
              }
          },
          offres_emploi: {
              includes: [
                'field_diplome',
                'field_entreprise',
                'field_entreprise.user_picture',
                'field_entreprise.entreprise_profiles',
                'field_filiere',
                'field_filiere.field_icon',
                'field_filiere.field_img_bg_bloc_meti',
                'field_formation_relation',
                'field_emploi_image_entreprise',
                'field_type_de_contrat',
              ],
              fields: {
                'file--file': ['uri', 'url', 'image_style_uri'],
                'profile--entreprise': ['field_adresse', 'field_geofield', 'field_raison_sociale', 'field_siret', 'field_info_de_l_entreprise', 'field_nombre_de_salaries'],
                'taxonomy_term--type_de_contrat': [
                  'name',
                  'description',
                  'drupal_internal__tid',
                  'path'
                ],
                'taxonomy_term--filiere': [
                  'name',
                  'description',
                  'drupal_internal__tid',
                  'path',
                  'field_image',
                  'field_icon',
                  'field_img_bg_bloc_meti',
                ],
              }
          },
          banniere_cta: {
            includes: [
              'field_banniere_image',
            ],
            fields: {
              'file--file': ['uri', 'url', 'image_style_uri']
            }
          },
          metier: {
              includes: [
                'field_diplome',
                'field_illustration_principale',
                'field_icone_metier',
                'field_formation_relation',
                'field_formation_relation.field_diplome',
                'field_filiere',
                'field_filiere.field_icon',
                'field_illustration_principale.field_media_image'
              ],
              fields: {
                'file--file': ['uri', 'url', 'image_style_uri'],
                'taxonomy_term--diplome': [
                  'name',
                  'description',
                  'field_diplome_acronyme',
                  'drupal_internal__tid',
                  'path',
                  'weight'
                ],
                'taxonomy_term--filiere': [
                  'name',
                  'description',
                  'drupal_internal__tid',
                  'path',
                  'field_image',
                  'field_icon',
                  'field_img_bg_bloc_meti',
                ],
              }
          },
          menu_restaurant: {
              includes: [
                'field_image_menu.field_media_image',
                'field_menu',
                'field_menu.field_para_menu_image_menu',
                'field_menu.field_image_menu.field_media_image',
                'field_image_menu_special',
              ],
              fields: {
                'file--file': ['uri', 'url', 'image_style_uri'],
                'paragraph--menu_restaurant': ['field_para_menu_dessert', 'field_para_menu_entree', 'field_image_menu', 'field_para_menu_image_menu', 'field_para_menu_plat', 'field_titre_du_menu'],
              }
          },
          evenement: {
            includes: [
              'field_type_evenement',
              'field_categorie',
              'field_illustration_principale',
              'field_illustration_principale.field_media_image',
              'field_filiere',
              'field_metier_concerne',
              'field_contenus_en_relation',
              'field_contenus_en_relation.field_illustration_principale',
              'field_contenus_en_relation.field_illustration_principale.field_media_image',
              'field_permission'
            ],
            fields: {
              'file--file': ['uri', 'url', 'image_style_uri'],
              'media--image': ['field_media_image'],
            }
          },
          page: {
            includes: [
              'field_contenus_en_relation',
              'field_contenus_en_relation.field_illustration_principale',
              'field_contenus_en_relation.field_illustration_principale.field_media_image'
            ]
          },
      },
      block_content: {
        zoom_sur: {
            includes: [
              'field_lien',
              'field_visuel_metier',
              'field_visuel_metier.field_media_image'
            ],
            fields: {
              'file--file': ['uri', 'url', 'image_style_uri'],
              'media--image': ['field_media_image'],
              'path_alias--path_alias': ['path', 'alias'],
            }
          },
          hero: {
              includes: [
                'field_visuel_hero',
                'field_visuel_hero.field_media_image'
              ],
              fields: {
                'file--file': ['uri', 'url', 'image_style_uri'],
                'media--image': ['field_media_image'],
              }
            },
      },
      terms :{
          filiere: {
              includes: [
                'field_image',
                'field_icon',
                'field_img_bg_bloc_meti',
              ],
              fields: {
                'file--file': ['uri', 'url', 'image_style_uri'],
                'taxonomy_term--filiere': [
                  'name',
                  'description',
                  'drupal_internal__tid',
                  'path',
                  'field_image',
                  'field_icon',
                  'field_img_bg_bloc_meti',
                ],
              }
          },
          diplome: {
              fields: {
                'taxonomy_term--diplome': [
                  'name',
                  'description',
                  'field_diplome_acronyme',
                  'drupal_internal__tid',
                  'path',
                  'weight'
                ],
              }
          },
          type_de_contrat: {
              fields: {
                'taxonomy_term--type_de_contrat': [
                  'name',
                  'description',
                  'drupal_internal__tid',
                  'path'
                ],
              }
          },
          categorie: {
              fields: {
                'taxonomy_term--categorie': [
                  'name',
                  'description',
                  'drupal_internal__tid',
                  'path'
                ],
              }
          }
      }
  },
  menuAdmin: [
    {
      elements: [
        {
          roles: ['Collaborateur', 'Administrateur', 'Admins site'],
          title: 'Agenda',
          url: '/agenda',
          icon: 'agenda',
        },
      ],
      separator: true,
    },
    {
      roles: ['Administrateur', 'Admins site'],
      elements: [
        {
          title: 'Contenus',
          url: '/backend/admin/content',
          icon: 'folder',
        },
        {
          title: 'Blocs',
          url: '/backend/admin/content/block',
          icon: 'blocks',
        },
        {
          title: 'Utilisateurs',
          url: '/backend/admin/people',
          icon: 'user',
        },
        {
          title: 'Menus navigation',
          url: '/backend/admin/structure/menu',
          icon: 'bars3',
        },
        {
          title: 'Taxonomies',
          url: '/backend/admin/structure/taxonomy',
          icon: 'tag',
        },
      ],
      separator: false,
    },
    {
      elements: [
        {
          roles: [
            'Administrateur',
            'Admins site',
            "Gestionnaire offres d'emploi",
          ],
          title: 'Entreprises',
          url: '/backend/admin/entreprises',
          icon: 'entreprise',
        },
      ],
      separator: true,
    },
    {
      roles: [
        'Administrateur',
        'Admins site',
        "Gestionnaire offres d'emploi",
      ],
      elements: [
        {
          title: "Offres d'emploi",
          url: '/backend/offres_emploi',
          icon: 'briefcase',
        },
        {
          title: 'Télécharger les candidatures',
          url: '/backend/admin/structure/webform/manage/candidature_offre_emploi/results/download',
          icon: 'arrowDownOnSquare',
        },
        {
          title: 'Supprimer les candidatures',
          url: '/backend/admin/structure/webform/manage/candidature_offre_emploi/results/clear',
          icon: 'archiveBoxXMark',
        },
      ],
      separator: true,
    },
    {
      roles: ['Entreprise', 'Employeurs', 'Employeur'],
      elements: [
        {
          title: "Mes offres d'emploi",
          url: '/mes-offres-emploi',
          icon: 'briefcase',
        },
      ],
      separator: true,
    },
    {
      roles: ['Gestionnaire restaurant'],
      elements: [
        {
          title: "Menus restaurant",
          url: '/backend/?title=&type=menu_restaurant&status=All&langcode=All',
          icon: 'cake',
        },
      ],
      separator: true,
    },
    {
      elements: [
        {
          title: 'Mon Profil',
          url: '/profile',
          icon: 'user',
        },
        {
          title: 'Déconnexion',
          relative: useLogout,
          icon: 'arrowLeftOnRectangle',
        },
      ],
      separator: false,
    },
  ],
  mobileMenuAdmin: [
    {
      title: 'Agenda',
      url: '/agenda',
      roles: ['Collaborateur', 'Administrateur', 'Admins site'],
    },
    {
      title: 'Contenus',
      url: '/backend/admin/content',
      roles: ['Administrateur', 'Admins site'],
    },
    {
      title: 'Blocs',
      url: '/backend/admin/content/block',
      roles: ['Administrateur', 'Admins site'],
    },
    {
      title: 'Utilisateurs',
      url: '/backend/admin/people',
      roles: ['Administrateur', 'Admins site'],
    },
    {
      title: 'Entreprises',
      url: '/backend/admin/entreprises',
      roles: [
        'Administrateur',
        'Admins site',
        "Gestionnaire offres d'emploi",
      ],
    },
    {
      title: 'Menus navigation',
      url: '/backend/admin/structure/menu',
      roles: ['Administrateur', 'Admins site'],
    },
    {
      title: 'Taxonomies',
      url: '/backend/admin/structure/taxonomy',
      roles: ['Administrateur', 'Admins site'],
    },
    {
      title: "Offres d'emploi",
      url: '/backend/offres_emploi',
      roles: [
        'Administrateur',
        'Admins site',
        'Entreprise',
        "Gestionnaire offres d'emploi",
      ],
    },
    {
      title: 'Télécharger les candidatures',
      url: '/backend/admin/structure/webform/manage/candidature_offre_emploi/results/download',
      roles: [
        'Administrateur',
        'Admins site',
        'Entreprise',
        "Gestionnaire offres d'emploi",
      ],
    },
    {
      title: 'Supprimer les candidatures',
      url: '/backend/admin/structure/webform/manage/candidature_offre_emploi/results/clear',
      roles: [
        'Administrateur',
        'Admins site',
        'Entreprise',
        "Gestionnaire offres d'emploi",
      ],
    },
    {
      title: 'Menus restaurant',
      url: '/backend/?title=&type=menu_restaurant&status=All&langcode=All',
      roles: ['Gestionnaire restaurant'],
    },
    {
      title: 'Mon Profile',
      url: '/profile',
    },
    {
      title: 'Déconnexion',
      function: useLogout,
    },
  ]
})
